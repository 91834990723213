import React, {useState} from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Document } from '@contentful/rich-text-types';
import { Form } from '../components/apply/form/form';
import { FormValueType, GDPRValueType } from '../apply/form/types';
import { HeaderContentfulProps } from '../components/header';
import { FooterBlockContentfulProps } from '../components/footer-block';
import { FooterContentfulProps } from '../components/footer';
import Teaser, { TeaserProps } from '../components/content-hub-items/teaser';
import { GdprBannerProps } from '../components/gdpr-banner';
import Cookies from 'js-cookie'; 
import cn from 'classnames';
import {
  Header,
  Footer,
  FooterBlock,
  GDPRBanner,
} from '../components';
import * as ContentTypes from '../components/content-hub-items';
import styles from './content-hub-page.module.scss';

interface ContentHubPageProps {
  data: {
    contentfulContentHubPage: {
      id: string;
      header: HeaderContentfulProps;
      footer: FooterContentfulProps;
      footerBlock: FooterBlockContentfulProps;
      title: {
        title: string;
      };
      summary: {
        summary: string;
      };
      bannerImage: {
        fluid: FluidObject | FluidObject[];
      };
      body: {
        json: Document;
      };
      contentHubItem: {
        __typename: string;
        id: string;
        hubspotFormId: string;
      };
      relatedItems: TeaserProps[];
    };
    contentfulGdprCookieBanner: GdprBannerProps;
  };
  pageContext: string;
  location: string;
}

enum StateEnum {
  APPLYING = 'applying',
  PROCESSING = 'process',
  SUCCESS = 'success',
  FAILURE = 'failure',
};


const ContentHubPage: React.FC<ContentHubPageProps> = ({
  data: {
    contentfulContentHubPage: contentHubPage,
    contentfulGdprCookieBanner
  },
  location,
}: ContentHubPageProps) => {
  const [state, setState] = useState(StateEnum.APPLYING);
  const [formValue, setFormValue] = useState<FormValueType>({});
  const [gdprOptions, setGDPROptions] = useState<GDPRValueType>({});
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const hubspotFormId = contentHubPage.contentHubItem.hubspotFormId;
  const teaserRowCounter = [0,0];
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    setState(StateEnum.PROCESSING);
    try {
      const result = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${hubspotFormId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': "application/json"
          },
          body: JSON.stringify({
              fields: Object.entries(formValue).map(
                ([questionId, answer]) => ({
                    name: questionId,
                    value: typeof(answer) === "object" ? Object.entries(answer).map((item) => { return item[1]; }).join(";") : answer,
                }),
              ),
              context: {
                hutk: Cookies.get('hubspotutk'),
                pageUri: window.location ? window.location.hostname + window.location.pathname : '',
                pageName: document ? document.title : ''
              },
              legalConsentOptions: gdprOptions.consent ? gdprOptions : null
          }),
        },
      );
      if (result.ok) {
        setState(StateEnum.SUCCESS);
        return;
      }
    }
    catch (e) {
      // do nothing
    }
    finally {
      // do nothing
    }
    setState(StateEnum.FAILURE);
  };

  if(contentHubPage) { 
    const contentType = ContentTypes.contentTypeMap[contentHubPage.contentHubItem.__typename];
    const {__typename: contentTypeName, id, ...contentParams} = contentHubPage.contentHubItem;
    const rightColumnForm = contentHubPage.contentHubItem.__typename !== 'ContentfulContentHubVideo';
    return (
      <div className={styles.contentHubPage}>
        <Helmet>
          <title>{contentHubPage.title.title || 'Reset Health'}</title>
          {/* metatags */}
        </Helmet>
        {contentHubPage.header && <Header {...contentHubPage.header} location={location}/>}
        <BackgroundImage className={styles.banner} style={{height: '350px', width: '100%' }} fluid={contentHubPage.bannerImage.fluid}>
          <div className={styles.inner}>
            <div className={styles.breadcrumbs}>
              <Link to="/">Home</Link>
              <Link to="/latest">Latest</Link>
              <Link to={contentType.breadcrumbUrl}>{contentType.breadcrumbLabel}</Link>
            </div>
            <h1 className={styles.title}>{contentHubPage.title.title}</h1>
          </div>
        </BackgroundImage>
        {contentType.component && <contentType.component {...contentParams} title={contentHubPage.title.title} summary={contentHubPage.summary.summary} body={contentHubPage.body} />}
        <div className={cn(styles.formWrapper, {[styles.rightAlignedForm]: rightColumnForm})}>
          {hubspotFormId &&[StateEnum.APPLYING, StateEnum.PROCESSING].includes(state) && (
          <Form
              formId={hubspotFormId}
              heading={null}
              gdprValue={gdprOptions}
              value={formValue}
              onGDPRChange={value =>  setGDPROptions(value)}
              onChange={value => setFormValue(value)}
              onValid={valid => setIsValid(valid)}
              showValidation={showValidation}
              hideNumbers={true}
            />)}
          {hubspotFormId && state === StateEnum.PROCESSING && <div className={styles.loader}/>}
          {hubspotFormId && state === StateEnum.APPLYING && ( <button className={cn(styles.button, styles.cta, styles.apply)} onClick={submitForm} >Submit</button>)}
          {hubspotFormId && state === StateEnum.SUCCESS && (<div className={styles.formResult}>Thanks for submitting.  Please check your e-mail for further instructions.</div>)}
          {hubspotFormId && state === StateEnum.FAILURE && (<div className={styles.formResult}>Something went wrong submitting the form.</div>)}
        </div>
        {contentHubPage.relatedItems && contentHubPage.relatedItems.length > 0 && (<h1 className={styles.relatedItemsHeader}>Related items</h1>)}
        <div className={styles.relatedItemsWrapper}>
        {contentHubPage.relatedItems && contentHubPage.relatedItems.map((page) => {
          //const tileHeight = (page.contentHubItem.__typename === 'ContentfulContentHubEBook' || page.contentHubItem.__typename === 'ContentfulContentHubVideo' || !page.teaserImage) ? 1 : 2;
          const tileHeight = 1;
          const currentColumn = teaserRowCounter[0] > teaserRowCounter[1] ? 1 : 0;
          const currentRow = teaserRowCounter[currentColumn];
          teaserRowCounter[currentColumn] = teaserRowCounter[currentColumn] + tileHeight;
          return <Teaser key={`teaser-${page.id}`} column={currentColumn} row={currentRow} tileHeight={tileHeight} {...page} />;
        })}
        </div>
        {contentHubPage.footer && (
          <Footer
            {...contentHubPage.footer}
          />
        )}
        {contentHubPage.footerBlock && (
          <FooterBlock
            {...page.footerBlock}
          />
        )}
        {contentfulGdprCookieBanner && (
          <GDPRBanner {...contentfulGdprCookieBanner} />
        )}
      </div>
    );
  }
  return null;
};

export default ContentHubPage;

export const pagesQuery = graphql`
  query contentHubPage($id: String!) {
    contentfulContentHubPage(id: { eq: $id }) {
      id
      header {
        ...HeaderFrag
      }
      footer {
        ...FooterFrag
      }
      metaTags {
        id
        key
        value {
          value
        }
      }
      title {
        title
      }
      bannerImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      contentHubItem {
        __typename
        ...BlogFrag
        ...EventFrag
        ...EBookFrag
        ...RecipeFrag
        ...VideoFrag
      }
      summary {
        summary
      }
      body {
        json
      }
      relatedItems {
        ...ContentHubTeaserFrag
      }
    }
  }
`;